// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.guess-list-container {
  text-align: center;
  color: white;
  pointer-events: none;
  z-index: 10;
  padding-bottom: 20px;
  width: 100%;
  margin-top: auto;
}

.sea-name {
  font-size: 16px;
  pointer-events: auto;
  padding: 10px 20px;
  background-color: #c0dffb;
  border-radius: 5px;
  color: #094073;
  display: inline-block;
  margin-bottom: 15px;
}

.guess-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  pointer-events: auto;
}

.guess-item {
  margin-bottom: 5px;
  pointer-events: auto;
  display: flex;
  justify-content: center;
}

.guess-actions {
  margin-top: 10px;
  pointer-events: auto;
  display: flex;
  justify-content: center;
}

.countdown-timer {
  font-size: 16px;
  padding: 10px 20px;
  background-color: #c0dffb;
  border-radius: 5px;
  color: #094073;
  margin-top: 15px;
  display: inline-block;
}

.countdown-timer p {
  margin: 0;
}

.bonus-round-button {
  margin-top: 0px;
  cursor: pointer;
  transition: opacity 0.2s ease;
  width: 350px;
  display: flex;
  justify-content: center;
}

.bonus-round-button:hover > div {
  background-color: #15803d !important;
}`, "",{"version":3,"sources":["webpack://./src/GuessList.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;EACZ,oBAAoB;EACpB,WAAW;EACX,oBAAoB;EACpB,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,oBAAoB;EACpB,kBAAkB;EAClB,yBAAyB;EACzB,kBAAkB;EAClB,cAAc;EACd,qBAAqB;EACrB,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,UAAU;EACV,SAAS;EACT,oBAAoB;AACtB;;AAEA;EACE,kBAAkB;EAClB,oBAAoB;EACpB,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,gBAAgB;EAChB,oBAAoB;EACpB,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,yBAAyB;EACzB,kBAAkB;EAClB,cAAc;EACd,gBAAgB;EAChB,qBAAqB;AACvB;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,eAAe;EACf,eAAe;EACf,6BAA6B;EAC7B,YAAY;EACZ,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,oCAAoC;AACtC","sourcesContent":[".guess-list-container {\n  text-align: center;\n  color: white;\n  pointer-events: none;\n  z-index: 10;\n  padding-bottom: 20px;\n  width: 100%;\n  margin-top: auto;\n}\n\n.sea-name {\n  font-size: 16px;\n  pointer-events: auto;\n  padding: 10px 20px;\n  background-color: #c0dffb;\n  border-radius: 5px;\n  color: #094073;\n  display: inline-block;\n  margin-bottom: 15px;\n}\n\n.guess-list {\n  list-style-type: none;\n  padding: 0;\n  margin: 0;\n  pointer-events: auto;\n}\n\n.guess-item {\n  margin-bottom: 5px;\n  pointer-events: auto;\n  display: flex;\n  justify-content: center;\n}\n\n.guess-actions {\n  margin-top: 10px;\n  pointer-events: auto;\n  display: flex;\n  justify-content: center;\n}\n\n.countdown-timer {\n  font-size: 16px;\n  padding: 10px 20px;\n  background-color: #c0dffb;\n  border-radius: 5px;\n  color: #094073;\n  margin-top: 15px;\n  display: inline-block;\n}\n\n.countdown-timer p {\n  margin: 0;\n}\n\n.bonus-round-button {\n  margin-top: 0px;\n  cursor: pointer;\n  transition: opacity 0.2s ease;\n  width: 350px;\n  display: flex;\n  justify-content: center;\n}\n\n.bonus-round-button:hover > div {\n  background-color: #15803d !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
