import React, { useState, useEffect, useCallback, useMemo } from 'react';
import axios from 'axios';
import { format } from 'date-fns';
import { auth } from './firebase';
import './CurrencyBonusRound.css';

const CurrencyBonusRound = ({ sea, onCorrectGuess }) => {
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [guessHistory, setGuessHistory] = useState([]);
  const [showAnswer, setShowAnswer] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [correctCurrency, setCorrectCurrency] = useState(null);

  const memoizedSea = useMemo(() => ({
    id: sea?.iso_a2,
    name: sea?.name
  }), [sea?.iso_a2, sea?.name]);

  const getButtonClass = useCallback((currencyCode) => {
    if (!showAnswer) return '';
    
    // If this is the correct currency, show it as correct
    if (currencyCode === correctCurrency) return 'correct';
    
    // If this was the user's incorrect guess, show it as incorrect
    const guess = guessHistory.find(g => g.currencyCode === currencyCode);
    if (guess && !guess.isCorrect) return 'incorrect';
    
    return '';
  }, [guessHistory, showAnswer, correctCurrency]);

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        const token = await auth.currentUser?.getIdToken();
        const currentDate = format(new Date(), 'yyyy-MM-dd');
        
        // Fetch previous game state
        const gameStateResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/gameState/fetch/${auth.currentUser?.uid}/${currentDate}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );

        if (!isMounted) return;

        if (gameStateResponse.data.gameState?.bonusRound?.currencyGuesses) {
          const guesses = gameStateResponse.data.gameState.bonusRound.currencyGuesses;
          setGuessHistory(guesses.map(guess => ({
            currencyCode: guess.selectedCurrency,
            isCorrect: guess.isCorrect
          })));
          if (guesses.length > 0) {
            setShowAnswer(true);
            // Set the correct currency from the response
            if (gameStateResponse.data.gameState.bonusRound.correctCurrency) {
              setCorrectCurrency(gameStateResponse.data.gameState.bonusRound.correctCurrency);
            }
          }
        }

        // Fetch currency options
        const currencyResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/currency-bonus-round/${currentDate}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );

        if (!isMounted) return;

        setCurrencyOptions(currencyResponse.data.options);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, []);

  const handleGuess = useCallback(async (currency) => {
    if (guessHistory.length > 0) return;
    
    try {
      const token = await auth.currentUser?.getIdToken();
      const currentDate = format(new Date(), 'yyyy-MM-dd');
      
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/currency-bonus-round/${currentDate}`,
        { selectedCurrency: currency },
        { headers: { Authorization: `Bearer ${token}` } }
      );
  
      const newGuess = {
        currencyCode: currency,
        isCorrect: response.data.isCorrect
      };
  
      setGuessHistory(prev => [...prev, newGuess]);
      setShowAnswer(true);
      setCorrectCurrency(response.data.correctCurrency);

      if (response.data.isCorrect) {
        onCorrectGuess?.();
      }
    } catch (error) {
      console.error('Error saving currency guess:', error);
    }
  }, [guessHistory, onCorrectGuess]);

  if (isLoading) {
    return <div className="currency-bonus-round-loading">Loading...</div>;
  }

  return (
    <div className="currency-bonus-round-container">
      <div className="currency-bonus-round-header">
        <h2>What is the currency of {memoizedSea.name}?</h2>
      </div>

      <div className="currency-options">
        {currencyOptions.map((option) => {
          const buttonClassName = `currency-option ${getButtonClass(option.currency)}`;

          return (
            <button
              key={option.currency}
              className={buttonClassName}
              onClick={() => !showAnswer && handleGuess(option.currency)}
              disabled={showAnswer}
            >
              {option.currency}
            </button>
          );
        })}
      </div>

      {showAnswer && (
        <div className="currency-result">
          <div className="result-text">
            {guessHistory.some(guess => guess.isCorrect) ? (
              <span className="correct-text">Correct!</span>
            ) : (
              <span className="incorrect-text">Incorrect</span>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(CurrencyBonusRound);