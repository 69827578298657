import React, { useState } from 'react';
import confetti from 'canvas-confetti';
import BonusRoundNav from './BonusRoundNav';
import BonusRound from './BonusRound';
import FlagBonusRound from './FlagBonusRound';
import CurrencyBonusRound from './CurrencyBonusRound';

const BonusWrapper = ({ sea, onReturn }) => {
  const [currentRound, setCurrentRound] = useState('population');

  const handleNavigate = (roundId) => {
    if (roundId === 'globe') {
      onReturn();
    } else {
      setCurrentRound(roundId);
    }
  };

  const triggerConfetti = () => {
    confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 },
    });
  };

  const renderBonusRound = () => {
    switch (currentRound) {
      case 'population':
        return (
          <BonusRound
            sea={sea}
            onReturn={onReturn}
            onNextChallenge={() => handleNavigate('flag')}
            onCorrectGuess={triggerConfetti}
          />
        );
      case 'flag':
        return (
          <FlagBonusRound
            sea={sea}
            onReturn={onReturn}
            onNextChallenge={() => handleNavigate('currency')}
            onCorrectGuess={triggerConfetti}
          />
        );
      case 'currency':
        return (
          <CurrencyBonusRound
            sea={sea}
            onReturn={onReturn}
            onNextChallenge={() => handleNavigate('globe')}
            onCorrectGuess={triggerConfetti}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen bg-slate-900">
      <div className="flex flex-col">
        <div className="w-full max-w-md mx-auto pt-4 px-4">
          <BonusRoundNav 
            currentRound={currentRound} 
            onNavigate={handleNavigate}
          />
        </div>

        <div>
          {renderBonusRound()}
        </div>
      </div>
    </div>
  );
};

export default BonusWrapper;