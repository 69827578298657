// src/ShareButton.js
import React, { useState } from 'react';
import { FaShareAlt } from 'react-icons/fa';

const ShareButton = ({ guesses, isWon, gameComplete }) => {
  const [isHovered, setIsHovered] = useState(false);

  const shareResults = () => {
    const startDate = new Date(2024, 4, 23); // May 21, 2024
    const today = new Date();
    const diffTime = Math.abs(today - startDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    
    const dateStr = today.toISOString().split('T')[0];
    const dateFormatted = `${dateStr.slice(8, 10)}.${dateStr.slice(5, 7)}.${dateStr.slice(0, 4)}`;

    const gameNumber = `#${diffDays}`;
    const guessesCount = isWon ? `${guesses.length}/3` : `X/3`;
    const closestProximity = `${Math.max(...guesses.map(guess => guess.proximity))}%`;
    
    const guessRows = guesses.map(guess => {
      const proximity = guess.proximity;
      const direction = guess.direction;

      let emojiRow = '';
      if (proximity === 100) {
        emojiRow = '🟩🟩🟩🟩🟩✅';
      }
        else if (proximity >= 90) {
        emojiRow = '🟩🟩🟩🟩🟩';
      } else if (proximity >= 80) {
        emojiRow = '🟩🟩🟩🟩⬛';
      } else if (proximity >= 60) {
        emojiRow = '🟩🟩🟩⬛⬛';
      } else if (proximity >= 40) {
        emojiRow = '🟩🟩⬛⬛⬛';
      } else if (proximity >= 30) {
        emojiRow = '🟩⬛⬛⬛⬛';
      }
      else {
        emojiRow = '⬛⬛⬛⬛⬛';
      }

      let directionArrow = '';
      if (proximity !== 100) {
        if (direction === 0) {
          directionArrow = '➡️';
        } else if (direction > 0 && direction <= 45) {
          directionArrow = '↘️';
        } else if (direction > 45 && direction <= 135) {
          directionArrow = '⬇️';
        } else if (direction > 135 && direction <= 180) {
          directionArrow = '⬅️';
        } else if (direction > 180 && direction <= 225) {
          directionArrow = '↖️';
        } else if (direction > 225 && direction <= 315) {
          directionArrow = '⬆️';
        } else {
          directionArrow = '↗️';
        }
      }

      return `${emojiRow}${directionArrow}`;
    }).join('\n');

    const shareText = `${gameNumber} Seale ${dateFormatted} ${guessesCount} (${closestProximity})\n${guessRows}`;

    if (navigator.share) {
      navigator.share({
        title: 'Seale Game Results',
        text: shareText,
        url: 'https://seale.world/',
      })
      .then(() => {
        console.log('Thanks for sharing!');
      })
      .catch(console.error);
    } else {
      navigator.clipboard.writeText(shareText)
        .then(() => {
          alert('Results copied to clipboard!');
        })
        .catch(err => {
          console.error('Could not copy text: ', err);
        });
    }
  };

  return (
    <button
      style={{
        ...styles.button,
        backgroundColor: isHovered ? '#222e49' : '#0f172a',
      }}
      onClick={shareResults}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <FaShareAlt style={styles.icon} />
      Share results
    </button>
  );
};

const styles = {
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '15px 20px',
    backgroundColor: '#1e293b',
    color: 'white',
    border: 'none',
    borderRadius: '8px',
    cursor: 'pointer',
    fontSize: '16px',
    fontWeight: 'bold',
    transition: 'all 0.2s ease',
    minWidth: '160px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
  icon: {
    marginRight: '10px',
    fontSize: '18px',
  },
};

export default ShareButton;
