import React from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';

const BonusRoundNav = ({ currentRound, onNavigate }) => {
  const rounds = [
    { id: 'globe', label: 'Globe', emoji: '🌍' },
    { id: 'population', label: 'Population', emoji: '👥' },
    { id: 'flag', label: 'Flag', emoji: '🚩' },
    { id: 'currency', label: 'Currency', emoji: '💰' }
  ];

  const currentIndex = rounds.findIndex(r => r.id === currentRound);

  const handlePrevious = () => {
    if (currentIndex > 0) {
      onNavigate(rounds[currentIndex - 1].id);
    }
  };

  const handleNext = () => {
    if (currentIndex < rounds.length - 1) {
      onNavigate(rounds[currentIndex + 1].id);
    }
  };

  const containerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    flexDirection: 'row'  // explicitly set to row
  };

  const buttonStyle = {
    width: '24px',
    height: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#1f2937',
    borderRadius: '8px',
    padding: '4px'
  };

  const selectStyle = {
    height: '32px',
    marginBottom: '4px',
    width: '148px',
    backgroundColor: '#166534',
    color: 'white',
    borderRadius: '8px',
    padding: '0 12px',
    fontSize: '14px',
    fontWeight: '600',
    marginLeft: '16px',
    marginRight: '16px'
  };

  return (
    <div style={containerStyle}>
      <button
        data-testid="previous-arrow-button"
        onClick={handlePrevious}
        disabled={currentIndex === 0}
        style={buttonStyle}
        type="button"
      >
        <ChevronLeft className="w-4 h-4" />
      </button>
      
      <select
        value={currentRound}
        onChange={(e) => onNavigate(e.target.value)}
        style={selectStyle}
      >
        {rounds.map(round => (
          <option key={round.id} value={round.id} style={{ backgroundColor: '#1f2937' }}>
            {round.emoji} {round.label}
          </option>
        ))}
      </select>

      <button
        data-testid="next-arrow-button"
        onClick={handleNext}
        disabled={currentIndex === rounds.length - 1}
        style={buttonStyle}
        type="button"
      >
        <ChevronRight className="w-4 h-4" />
      </button>
    </div>
  );
};

export default BonusRoundNav;