import React from 'react';
import ProgressInfo from './ProgressInfo';
import ShareButton from './ShareButton';
import MapsButton from './MapsButton';
import './GuessList.css';

// In GuessList.js
const GuessList = ({ sea, guesses, gameComplete, isWon, nextPuzzleTime, onBonusRound }) => {
  const totalGuesses = [...guesses];

  // Add ghost guesses if there are less than 3 actual guesses
  if (!gameComplete) {
    while (totalGuesses.length < 3) {
      totalGuesses.push({ proximity: null, direction: null, isCorrect: false, isGhost: true, ghostNumber: totalGuesses.length + 1 });
    }
  }

  const handleBonusRound = () => {
    if (typeof onBonusRound === 'function') {
      onBonusRound();
    } else {
      console.log('Starting bonus round...');
    }
  };

  return (
    <div className="guess-list-container">
      {sea && (
        <div className="sea-name">
          {`${sea.name.toUpperCase()}`}
        </div>
      )}
      <ul className="guess-list">
        {totalGuesses.map((guess, index) => (
          <li key={index} className="guess-item">
            <ProgressInfo 
              proximity={guess.proximity} 
              direction={guess.direction} 
              isCorrect={guess.isCorrect} 
              isGhost={guess.isGhost} 
              ghostNumber={guess.ghostNumber} 
            />          
          </li>
        ))}
        {gameComplete && (
          <li className="guess-item">
            <div className="bonus-round-button" onClick={handleBonusRound}>
              <ProgressInfo 
                isCorrect={true}
                customText="🎁 Play bonus round"
              />
            </div>
          </li>
        )}
        {gameComplete && (
          <li className="guess-actions">
            {sea && <MapsButton seaName={sea.name} />}
            <ShareButton guesses={guesses} isWon={isWon} gameComplete={gameComplete} />
          </li>
        )}
        {gameComplete && (
          <li className="countdown-timer">
            <p>Time until next puzzle: {nextPuzzleTime}</p>
          </li>
        )}
      </ul>
    </div>
  );
};

export default GuessList;