import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { format } from 'date-fns';
import { auth } from './firebase';
import './BonusRound.css';
import ProgressInfo from './ProgressInfo';

const BonusRound = ({ sea, onNextChallenge, onCorrectGuess }) => {
  const [selectedRange, setSelectedRange] = useState(null);
  const [showAnswer, setShowAnswer] = useState(false);
  const [populationRanges, setPopulationRanges] = useState([]);
  const [previousGuess, setPreviousGuess] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [svgContent, setSvgContent] = useState(null);

  // Memoize the sea object's relevant properties
  const memoizedSea = useMemo(() => ({
    id: sea?.iso_a2,
    name: sea?.name,
    POP_EST: sea?.POP_EST
  }), [sea?.iso_a2, sea?.name, sea?.POP_EST]);

  // Helper function to round population to nice numbers
  const roundToNiceMillion = (num) => {
    const inMillions = num / 1000000;
    if (inMillions >= 10) {
      return Math.round(inMillions / 5) * 5 * 1000000;
    } else {
      return Math.round(inMillions * 10) / 10 * 1000000;
    }
  };

  const formatPopulation = (num) => {
    const inMillions = num / 1000000;
    if (inMillions >= 10) {
      return `${Math.round(inMillions)}M`;
    } else if (inMillions >= 1) {
      return `${inMillions.toFixed(1)}M`;
    } else {
      return `${Math.round(num / 1000)}K`;
    }
  };

  // Generate deterministic population ranges based on actual population
  const generatePopulationRanges = (actualPopulation, date) => {
    const roundedPopulation = roundToNiceMillion(actualPopulation);
    
    // Create a deterministic seed based on the date and country population
    const dateNum = parseInt(date.split('-').join(''));
    const seed = (dateNum + Math.floor(actualPopulation / 1000000)) % 4;
    
    // Determine which range should contain the actual population
    const correctRangeIndex = seed;
    
    let ranges = [];
    let baseValue;

    // Adjust the base value depending on which range should be correct
    switch(correctRangeIndex) {
      case 0: // Correct answer should be in "< X" range
        baseValue = roundToNiceMillion(actualPopulation * 2);
        break;
      case 1: // Correct answer should be in "X - Y" range
        baseValue = roundToNiceMillion(actualPopulation * 1.4);
        break;
      case 2: // Correct answer should be in "Y - Z" range
        baseValue = roundToNiceMillion(actualPopulation);
        break;
      case 3: // Correct answer should be in "Z+" range
        baseValue = roundToNiceMillion(actualPopulation * 0.7);
        break;
    }

    ranges = [
      {
        id: 1,
        min: 0,
        max: baseValue * 0.6,
        label: `< ${formatPopulation(baseValue * 0.6)}`
      },
      {
        id: 2,
        min: baseValue * 0.6,
        max: baseValue,
        label: `${formatPopulation(baseValue * 0.6)} - ${formatPopulation(baseValue)}`
      },
      {
        id: 3,
        min: baseValue,
        max: baseValue * 1.4,
        label: `${formatPopulation(baseValue)} - ${formatPopulation(baseValue * 1.4)}`
      },
      {
        id: 4,
        min: baseValue * 1.4,
        max: Infinity,
        label: `${formatPopulation(baseValue * 1.4)}+`
      }
    ];

    return ranges;
  };

useEffect(() => {
    // console.log("useEffect triggered with sea:", memoizedSea);
    if (memoizedSea.id) {
      // console.log("Attempting to fetch country path:", memoizedSea.id);
      
      // Get the current user's token
      auth.currentUser?.getIdToken()
        .then(token => {
          return fetch(`${process.env.REACT_APP_API_URL}/api/country-path/${memoizedSea.id}`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
        })
        .then(response => {
          // console.log("Path fetch response status:", response.status);
          if (!response.ok) throw new Error('Failed to load country path');
          return response.json();
        })
        .then(data => {
          // console.log("Path data received for:", data.title);
          const pathData = data.d;
          
          // Parse coordinates
          const coordinates = [];
          const pathParts = pathData.match(/[A-Za-z][^A-Za-z]*/g) || [];
          let currentX = 0;
          let currentY = 0;
  
          pathParts.forEach(part => {
            const command = part[0];
            const args = part.slice(1).trim().split(/[\s,]+/).map(Number);
            
            if (command === 'M' || command === 'L') {
              for (let i = 0; i < args.length; i += 2) {
                coordinates.push([args[i], args[i + 1]]);
              }
              currentX = args[args.length - 2];
              currentY = args[args.length - 1];
            } else if (command === 'm' || command === 'l') {
              for (let i = 0; i < args.length; i += 2) {
                currentX += args[i];
                currentY += args[i + 1];
                coordinates.push([currentX, currentY]);
              }
            }
          });
            
          // console.log("Parsed coordinates count:", coordinates.length);
    
          // Calculate bounds
          const minX = Math.min(...coordinates.map(c => c[0]));
          const maxX = Math.max(...coordinates.map(c => c[0]));
          const minY = Math.min(...coordinates.map(c => c[1]));
          const maxY = Math.max(...coordinates.map(c => c[1]));
          
          // Calculate dimensions and center
          const width = maxX - minX;
          const height = maxY - minY;
          const centerX = (minX + maxX) / 2;
          const centerY = (minY + maxY) / 2;
  
          // Calculate scale while maintaining aspect ratio
          const targetSize = 350;
          const scale = Math.min(targetSize / width, targetSize / height);
          const finalScale = Math.min(Math.max(scale, 0.5), 20);
  
          // Calculate translation to center the path
          const translationX = 200 - (centerX * finalScale);
          const translationY = 200 - (centerY * finalScale);
    
          const svgContent = `
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              viewBox="0 0 400 400"
              width="400" 
              height="400" 
              preserveAspectRatio="xMidYMid meet"
            >
              <style>
                .country-path {
                  fill: #4CAF50;
                  stroke: #2E7D32;
                  stroke-width: ${1.5 / finalScale};
                  vector-effect: non-scaling-stroke;
                }
              </style>
              <g transform="translate(${translationX}, ${translationY}) scale(${finalScale})">
                <path 
                  d="${pathData}"
                  class="country-path"
                />
              </g>
            </svg>
          `;
          
          setSvgContent(svgContent);
        })
        .catch(error => {
          console.error('Error in SVG processing:', error);
        });
    }
  }, [memoizedSea]);
  
  // // Add debug logging for when SVG content changes
  // useEffect(() => {
  //   // console.log("SVG content updated:", svgContent ? 'yes' : 'no');
  //   if (svgContent) {
  //     console.log("SVG HTML:", svgContent.outerHTML);
  //   }
  // }, [svgContent]);

  // Fetch previous game state including bonus round
  useEffect(() => {
    const fetchPreviousGuess = async () => {
      try {
        const token = await auth.currentUser?.getIdToken();
        const currentDate = format(new Date(), 'yyyy-MM-dd');
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/gameState/fetch/${auth.currentUser?.uid}/${currentDate}`,
          {
            headers: { Authorization: `Bearer ${token}` }
          }
        );

        if (response.data.gameState?.bonusRound?.completed) {
          setPreviousGuess(response.data.gameState.bonusRound);
          setShowAnswer(true);
        }
      } catch (error) {
        console.error('Error fetching previous bonus round guess:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPreviousGuess();
  }, []);

  // Generate population ranges when sea data is available
  useEffect(() => {
    if (memoizedSea.POP_EST) {
      const currentDate = format(new Date(), 'yyyy-MM-dd');
      const ranges = generatePopulationRanges(memoizedSea.POP_EST, currentDate);
      setPopulationRanges(ranges);
      
      if (previousGuess) {
        const prevRange = ranges.find(range => 
          range.min === previousGuess.populationGuess.range.min &&
          range.max === previousGuess.populationGuess.range.max
        );
        if (prevRange) {
          setSelectedRange(prevRange.id);
        }
      }
    }
  }, [memoizedSea.POP_EST, previousGuess]);

  const handleGuess = async (range) => {
    if (previousGuess) return;
    
    setSelectedRange(range.id);
    setShowAnswer(true);

    const isCorrect = memoizedSea.POP_EST >= range.min && memoizedSea.POP_EST < range.max;
    if (isCorrect) {
      onCorrectGuess?.();
    }
    
    try {
      const token = await auth.currentUser?.getIdToken();
      const currentDate = format(new Date(), 'yyyy-MM-dd');
      
      await axios.post(
        `${process.env.REACT_APP_API_URL}/bonus-round/${currentDate}`, 
        { 
          guessRange: {
            min: range.min,
            max: range.max,
          }
        },
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
    } catch (error) {
      console.error('Error saving bonus round guess:', error);
    }
  };

  const isCorrectRange = (range) => {
    return memoizedSea.POP_EST >= range.min && memoizedSea.POP_EST < range.max;
  };

  const getButtonClass = (range) => {
    if (!showAnswer) return '';
    const isSelected = selectedRange === range.id;
    const isCorrect = isCorrectRange(range);
    if (isCorrect) return 'correct';
    if (isSelected && !isCorrect) return 'incorrect';
    return '';
  };

  if (isLoading || !memoizedSea.POP_EST) {
    return <div className="bonus-round-loading">Loading...</div>;
  }

  return (
        <div className="bonus-round-container">
        {/* {svgContent && (
          <div className="country-map-container">
            <div
              dangerouslySetInnerHTML={{ 
                __html: svgContent 
              }}
              className="country-map"
            />
          </div>
        )} */}
      <div className="bonus-round-header">
        
        <h2>What is the estimated population of {memoizedSea.name}?</h2>
      </div>

      <div className="population-options">
        {populationRanges.map((range) => (
          <button
            key={range.id}
            className={`population-option ${getButtonClass(range)}`}
            onClick={() => handleGuess(range)}
            disabled={showAnswer}
          >
            {range.label}
          </button>
        ))}
      </div>

      {showAnswer && (
        <div className="flag-result">
          <div className="population-number">
            Population: {sea.POP_EST.toLocaleString()}
          </div>
          <div className="bonus-navigation-buttons">
            <div className="next-challenge-button" onClick={onNextChallenge}>
              <ProgressInfo 
                isCorrect={true}
                customText="🚩 Next Challenge: Flag Quiz"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default BonusRound;